import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/rml/form',
    name: 'rmlform',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/new',
    name: 'newlayout',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/meetings/new',
    name: 'meetingscreate',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/meetings/:type?',
    name: 'meetings',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/meeting/:id',
    name: 'meetingdetail',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/meeting/:id',
    name: 'meetingdetail2',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/',
    name: 'logincheck',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/experts',
    name: 'experts',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/iframe/:id/:lang',
    name: 'expertiframe',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/expert/:slug/:id',
    name: 'expert',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/chats',
    name: 'chats',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/chat/overview',
    name: 'chatDetail',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/chat/:id',
    name: 'chatDetail',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/mailsettings',
    name: 'adminmailsettings',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/change-password',
    name: 'changepassword',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/booking/:id',
    name: 'bookingsdetail',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/success/:id',
    name: 'success',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/success/iframe',
    name: 'successiframe',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/assets',
    name: 'assetsadmin',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/asset/:id',
    name: 'asseteditadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/asset/new',
    name: 'assetcreateadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/categories',
    name: 'categoriesadmin',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/category/new',
    name: 'categorycreateadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/category/:id',
    name: 'categoryeditadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/weeks',
    name: 'weeksadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/settings',
    name: 'settingsadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  // {
  //   path: '/booking/confirm/:id',
  //   name: 'bookingconfirm',
  //   component: () => import('../views/bookings/ConfirmView.vue'),
  //   props: true
  // },
  {
    path: '/admin/bookings/:type?',
    name: 'bookingsadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/booking/:id',
    name: 'bookingsdetailadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/tabs',
    name: 'tabsadmin',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/tabs/new',
    name: 'tabscreateadmin',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/tabs/:id',
    name: 'tabseditadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/fields/new',
    name: 'fieldscreateadmin',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/fields/:id',
    name: 'fieldseditadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  {
    path: '/admin/users',
    name: 'usersadmin',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/users/new',
    name: 'userscreateadmin',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/users/:id',
    name: 'userseditadmin',
    component: () => import('../views/_new/BaseView.vue'),
    props: true
  },
  // {
  //   path: '/admin/vouchers',
  //   name: 'vouchersadmin',
  //   component: () => import('../views/vouchers/admin/IndexView.vue')
  // },
  // {
  //   path: '/admin/vouchers/new',
  //   name: 'voucherscreateadmin',
  //   component: () => import('../views/vouchers/admin/CreateForm.vue')
  // },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/admin/stats',
    name: 'statsadmin',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/_new/BaseView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/auth/LogoutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export default router
